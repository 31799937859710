module.exports = [{
      plugin: require('../../../.yarn/unplugged/gatsby-plugin-mdx-virtual-49d5bbce15/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/.yarn/cache/gatsby-remark-table-of-contents-npm-1.0.0-4036650436-fbbcede0cf.zip/node_modules/gatsby-remark-table-of-contents/dist/gatsby-remark-table-of-contents.cjs.js"},{"resolve":"/opt/build/repo/.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-c7ea295503/0/cache/gatsby-remark-autolink-headers-npm-4.4.0-0156526a76-98937a48a1.zip/node_modules/gatsby-remark-autolink-headers/index.js","options":{"offsetY":"100"}},{"resolve":"/opt/build/repo/.yarn/__virtual__/gatsby-remark-prismjs-virtual-b218d24718/0/cache/gatsby-remark-prismjs-npm-5.4.0-d1df40d952-677add50a5.zip/node_modules/gatsby-remark-prismjs/index.js"}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/packages/gatsby"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-1db266ee51/0/cache/gatsby-plugin-catch-links-npm-3.7.0-ced13170c1-357c1ca943.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"^\\/(api|playground)$"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
