// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-configuration-manifest-js": () => import("./../../../src/pages/configuration/manifest.js" /* webpackChunkName: "component---src-pages-configuration-manifest-js" */),
  "component---src-pages-configuration-yarnrc-js": () => import("./../../../src/pages/configuration/yarnrc.js" /* webpackChunkName: "component---src-pages-configuration-yarnrc-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-package-js": () => import("./../../../src/pages/package.js" /* webpackChunkName: "component---src-pages-package-js" */),
  "component---src-pages-playground-frame-js": () => import("./../../../src/pages/playground/frame.js" /* webpackChunkName: "component---src-pages-playground-frame-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

